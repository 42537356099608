import React from 'react';
import { Box, Container, Grid } from 'theme-ui';
import BlockTitle from 'components/block-title';
import ServiceCard from 'components/cards/service-card';
import serviceImage1 from 'assets/stopwatch.png';
import serviceImage2 from 'assets/phone.png';
import serviceImage3 from 'assets/gadgets.png';
import serviceImage4 from 'assets/pie-chart.png';
import serviceImage5 from 'assets/recruitment.png';
import serviceImage6 from 'assets/robot-assistant.png';

const SERVICES_DATA = [
  {
    image: serviceImage1,
    text:
      'Know as soon as your documents are opened and see their engagement in real-time.',
    heading: 'Real-time Notifications',
    path: '#',
  },
  {
    image: serviceImage2,
    text:
      'Instant notification helps you get the time of lead engagement which helps in timing your sales pitch.',
    heading: 'Time your Sales Call',
    path: '#',
  },
  {
    image: serviceImage3,
    text:
      'Anyone can view your documents without any compatibility issues, no matter what device they use.',
    heading: 'Works Anywhere',
    path: '#',
  },
  {
    image: serviceImage4,
    text:
      'See how well your content is performing overall with engagement and drop-off reports.',
    heading: 'Document Performance Analytics',
    path: '#',
  },
  {
    image: serviceImage5,
    text:
      "Capture viewer details, know what they’re interested in, how interested they are and when do they download the document.",
    heading: 'Lead Insights',
    path: '#',
  },
  {
    image: serviceImage6,
    text:
      'Send your viewers engagement and information directly to 7Targets AI Sales Assistant.',
    heading: '7Targets Integration',
    path: 'https://7targets.ai/help/how-to/how-to-use-doculens-with-7targets/',
  },
];
const Services = () => {
  return (
    <Box as="section" id="services" sx={styles.services}>
      <Container>
        <BlockTitle
          title="Supercharge your Documents"
          text="Get most accurate lead insights on any device"
        />
        <Grid sx={styles.grid}>
          {SERVICES_DATA.map(({ image, text, heading, path }, index) => (
            <ServiceCard
              image={image}
              text={text}
              heading={heading}
              path={path}
              key={index}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;

const styles = {
  services: {
    pt: ['80px', null, null, null, '80px', null, '100px'],
  },
  grid: {
    gridGap: '30px',
    gridTemplateColumns: ['1fr', null, null, '1fr 1fr', null, '1fr 1fr 1fr'],
  },
};
