import React from 'react';
import { Box, Container, Flex, Text, Heading } from 'theme-ui';
import { Link } from 'components/link';
import BlockTitle from 'components/block-title';
import Accordion from 'components/accordion/accordion';

const accordionData = [
  {
    isExpanded: false,
    title: 'How secure are the pdf files kept in DocuLens? ',
    contents: (
      <div>
        Although we have tried to make it safe, we dont advise to put sensitive documents here. Use it more for getting insights into your public marketing material.
      </div>
    ),
  },
  {
    isExpanded: true,
    title: 'Can anyone else apart from client access it?',
    contents: (
      <div>
        Anyone with the document sharing link can access the document.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Are my documents encrypted? ',
    contents: (
      <div>
        Documents are encrypted using the AWS S3 encryption policy.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'How do we get the documents on doculens?',
    contents: (
      <div>
        Signup on Doculens.io. 
        After login, you will see a 'Upload button'. 
        You can get your documents on doculens using this 'Upload button'. 
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Can I use DocuLens links on WhatsApp or on Website?',
    contents: (
      <div>
        You can use DocuLens document sharing link anywhere as far as user is able to access the link. It can be on any social media platform or you company website.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Is it only for .pdf format or any format is allowed?',
    contents: (
      <div>
        It is only pdf format. You hahe to convert your documents into pdf before uploading. 
        Word-{'>'}Save as pdf
        PPT-{'>'}Save as pdf first, then upload 
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Can I set up a link so it does not ask email address? How? ',
    contents: (
      <div>
        You already know the email address to whom you are sending a document. You dont want your DocuLens to again ask email address. 
        DocuLens link end with ?email= 
        Put an email address of the person you are sending this document to at the end of this link. This way DocuLens won't prompt for an email address. 
        Another way to prevent DocuLens from asking email address is add 'noreply@doculens.io' at the end of this shared link. In this case, you want to know who opened the document. You will know which pages were seen for how many seconds.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'For how long, can the documents be tracked?',
    contents: (
      <div>
        Once the pdf is in Doculens, you can track it for any number of years, till your account is active.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Can I get refund?',
    contents: (
      <div>
        No, we dont offer any refund.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Can I change my plan? ',
    contents: (
      <div>
        Yes, plan can be changed anytime. Just contact support@doculens.io
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'How to stop my plan? ',
    contents: (
      <div>
        Just write an email to support@doculens.io
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Which cloud is used for DocuLens?',
    contents: (
      <div>
        For DocuLens we are using AWS cloud for storing and processing of docuemnts.
      </div>
    ),
  }
];

const FAQ = () => {
  return (
    <Box as="section" id='faq'>
      <Container sx={styles.container}>
        <BlockTitle
          title="Frequently Ask Question"
          text="Ask your question and meet"
        />
        <Flex sx={styles.flex}>
          <Box sx={styles.faqWrapper}>
            <Accordion items={accordionData} />
          </Box>
          <Box sx={styles.content}>
            <Heading as="h3">
              Do you have any question? Please ask here we ready to support
            </Heading>
            <Text as="p">
              If your question is not list here, please feel free to make a
              manual support.
            </Text>
            <Link sx={styles.askButton} href="mailto:support@doculens.io">
              Ask your Question
            </Link>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default FAQ;

const styles = {
  container: {
    // position: 'relative',
    // top: '150px',
    mt: '100px',
  },
  flex: {
    flexWrap: 'wrap',
    flexDirection: ['column', null, null, null, null, 'row-reverse'],
    pb: ['70px', null, null, null, '90px', null, '130px'],
  },
  content: {
    flex: ['0 0 100%', null, null, null, '0 0 33.333%'],
    maxWidth: ['100%', null, null, '450px', '100%'],
    mx: ['auto', null, null, null, '0'],
    mb: ['0px', null, null, null, '0'],
    textAlign: ['center', null, null, null, null, 'left'],
    mt: ['40px', null, null, null, null, '0'],
    h3: {
      fontSize: ['23px', null, null, null, '24px'],
      lineHeight: [1.5, null, null, null, 1.67],
      color: 'black',
      fontWeight: 700,
      letterSpacing: '-1.5px',
      mt: '-5px',
      pr: ['0', null, null, null, null, '30px'],
    },
    p: {
      fontSize: '16px',
      lineHeight: 1.87,
      color: '#343D48',
      opacity: 0.7,
      mt: '10px',
      pr: ['0', null, null, null, null, '80px'],
    },
  },
  askButton: {
    display: 'inline-block',
    verticalAlign: 'middle',
    backgroundColor: '#8c52ff',
    color: '#fff',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 700,
    p: '6.5px 19px',
    letterSpacing: '-0.16px',
    mt: '25px',
    transition: 'all 500ms ease',
    '&:hover': {
      opacity: 0.8,
    },
  },
  faqWrapper: {
    flex: ['0 0 100%', null, null, null, '0 0 66.666%'],
  },
};
