import React, { useState } from 'react';
import { Box, Container, Button } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { keyframes } from '@emotion/core';
import BlockTitle from 'components/block-title';
import Image from 'components/image';
import { AiFillFolderOpen, AiOutlineFilePdf, AiOutlineBarChart } from 'react-icons/ai';
import dotPattern from 'assets/dot-pattern.svg';

const Featured = () => {
  const data = useStaticQuery(graphql`
    query {
      documentsImage: file(relativePath: { eq: "documents-table.png" }) {
        childImageSharp {
          fluid(maxWidth: 1326) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      pdfReaderImage: file(relativePath: { eq: "pdf-reader.png" }) {
        childImageSharp {
          fluid(maxWidth: 1326) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      viewsImage: file(relativePath: { eq: "view-table.png" }) {
        childImageSharp {
          fluid(maxWidth: 1326) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [tab, setTab] = useState({
    active: 'documents',
  });

  const handleTab = (tab) => {
    if (tab === 'documents') {
      setTab({
        ...tab,
        active: 'documents',
      });
    }
    if (tab === 'pdfReader') {
      setTab({
        ...tab,
        active: 'pdfReader',
      });
    }
    if (tab === 'views') {
      setTab({
        ...tab,
        active: 'views',
      });
    }
  };
  return (
    <Box as="section" sx={styles.featured}>
      <Container sx={styles.container}>
        <BlockTitle
          title="Simple and Straightforward"
          text="Easily manage, upload and track documents in a user friendly way"
        />
        <Box sx={styles.tabButtonTopWrapper}>
          <Box sx={styles.tabButtonWrapper}>
            <Button
              onClick={() => handleTab('documents')}
              className={`${tab.active === 'documents' ? 'active' : ''}`}
            >
              <AiFillFolderOpen />
              View and Manage Documents
            </Button>
            <Button
              onClick={() => handleTab('pdfReader')}
              className={`${tab.active === 'pdfReader' ? 'active' : ''}`}
            >
              <AiOutlineFilePdf />
              Advanced PDF Reader
            </Button>
            <Button
              onClick={() => handleTab('views')}
              className={`${tab.active === 'views' ? 'active' : ''}`}
            >
              <AiOutlineBarChart />
              View Reports and Analysis
            </Button>
          </Box>
        </Box>
        <Box sx={styles.tabContent}>
          {tab.active === 'documents' && (
            <Image
              src={data.documentsImage.childImageSharp.fluid}
              alt="tab image"
              className="tabImage"
            />
          )}
          {tab.active === 'pdfReader' && (
            <Image
              src={data.pdfReaderImage.childImageSharp.fluid}
              alt="tab image"
              className="tabImage"
            />
          )}
          {tab.active === 'views' && (
            <Image
              src={data.viewsImage.childImageSharp.fluid}
              alt="tab image"
              className="tabImage"
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Featured;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const styles = {
  featured: {
    pt: ['80px', null, null, null, '80px', null, '100px'],
    backgroundColor: '#F9FAFC',
  },
  container: {
    position: 'relative',
    top: '150px',
    mt: '-150px',
  },
  tabButtonTopWrapper: {
    overflowY: ['hidden', null, null, null, null, 'inherit'],
    overflowX: ['auto', null, null, null, null, 'inherit'],
  },
  tabButtonWrapper: {
    width: ['700px', null, null, null, null, '100%'],
    mx: ['auto', null, null, null, null, '0'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid rgba(1,7,13,.1)',
    mb: '35px',
    button: {
      display: 'flex',
      alignItems: 'center',
      pb: ['15px', null, null, null, '35px'],
      px: ['15px', null, null, null, '30px'],
      flexShrink: '0',
      border: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      color: '#0F2137',
      fontSize: ['14px', null, null, null, '18px'],
      fontWeight: 500,
      lineHeight: 1,
      position: 'relative',
      transition: 'all 500ms ease',
      svg: {
        fontSize: ['18px', null, null, null, '30px'],
        color: '#ADBDD0',
        opacity: 0.7,
        mr: '15px',
        transition: 'all 500ms ease',
      },
      '&:hover, &.active': {
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#0F2137',
        svg: {
          color: '#0F2137',
          opacity: 1,
        },
        '&::before': {
          transform: 'scale(1,1)',
        },
      },
      '&::before': {
        content: "''",
        position: 'absolute',
        bottom: '-2px',
        backgroundColor: '#0F2137',
        left: 0,
        width: '100%',
        height: '3px',
        transform: 'scale(0,1)',
        transition: 'transform 500ms ease',
      },
    },
  },
  tabContent: {
    minHeight: ['190px', null, '300px', '385px', null, '600px'],
    position: 'relative',
    '&::before': {
      content: "''",
      width: '302px',
      height: '347px',
      backgroundImage: `url(${dotPattern})`,
      position: 'absolute',
      bottom: '-30px',
      right: '-40px',
      display: ['none', null, null, null, null, 'block'],
    },
    '.tabImage': {
      position: 'relative',
      animation: `${fadeIn} 0.8s linear`,
    },
  },
};
